/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUpdateExportResultByCompanyIdAndExportRequestId = /* GraphQL */ `subscription OnUpdateExportResultByCompanyIdAndExportRequestId(
  $companyId: String!
  $exportRequestId: String!
) {
  onUpdateExportResultByCompanyIdAndExportRequestId(
    companyId: $companyId
    exportRequestId: $exportRequestId
  ) {
    companyId
    exportRequestId
    createdAt
    status
    error {
      errorType
      errorCode
    }
    s3Object {
      bucket
      key
      region
    }
    event
    accountId
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    requestAt
    exportedAt
    expiredAt
    count
    ttl
    companyIdAndEvent
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateExportResultByCompanyIdAndExportRequestIdSubscriptionVariables,
  APITypes.OnUpdateExportResultByCompanyIdAndExportRequestIdSubscription
>;
export const onCreateAuthori = /* GraphQL */ `subscription OnCreateAuthori($filter: ModelSubscriptionAuthoriFilterInput) {
  onCreateAuthori(filter: $filter) {
    id
    createdAt
    updatedAt
    expirationUnixTime
    authori_id
    omotion_user_id
    company_id
    send_token
    event_id
    event_name
    user_id_hashed
    user_id_encrypted
    result
    final_result
    reason
    feedback
    feedback_comment
    feedbacked_at
    feedback_type
    feedback_account_id
    feedback_account_name
    user_device_id
    event_url
    login_success
    cookie
    etag
    local_storage
    input_type
    bot_flag
    bot_type
    did_short
    did_middle
    source_ip
    tor_ip_flag
    foreign_ip_flag
    useragent
    wt_device_category_name
    wt_os_name
    wt_os_version
    wt_browser_name
    wt_browser_version
    browser_language
    referer
    timezone_offset
    access_at
    send_at
    request_at
    began_at
    ended_at
    login_time
    residence_time
    authori_time
    es_index_date
    es_company_index
    ip_version
    ip_country_code
    ip_country_a_name
    ip_country_j_name
    ip_pref_code
    ip_pref_a_name
    ip_pref_j_name
    ip_city_code
    ip_city_a_name
    ip_city_j_name
    ip_org_name
    ip_domain_name
    ip_line_j_name
    ip_proxy_flag
    ip_pref_cf_level
    ip_city_cf_level
    ip_line_cf_level
    ip_pref_location
    ip_city_location
    ip_read_time
    ip_created
    click_count
    keystroke_count
    mouse_count
    swipe_count
    remarks
    created_at
    updated_at
    labels
    memo
    connected_id
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAuthoriSubscriptionVariables,
  APITypes.OnCreateAuthoriSubscription
>;
export const onUpdateAuthori = /* GraphQL */ `subscription OnUpdateAuthori($filter: ModelSubscriptionAuthoriFilterInput) {
  onUpdateAuthori(filter: $filter) {
    id
    createdAt
    updatedAt
    expirationUnixTime
    authori_id
    omotion_user_id
    company_id
    send_token
    event_id
    event_name
    user_id_hashed
    user_id_encrypted
    result
    final_result
    reason
    feedback
    feedback_comment
    feedbacked_at
    feedback_type
    feedback_account_id
    feedback_account_name
    user_device_id
    event_url
    login_success
    cookie
    etag
    local_storage
    input_type
    bot_flag
    bot_type
    did_short
    did_middle
    source_ip
    tor_ip_flag
    foreign_ip_flag
    useragent
    wt_device_category_name
    wt_os_name
    wt_os_version
    wt_browser_name
    wt_browser_version
    browser_language
    referer
    timezone_offset
    access_at
    send_at
    request_at
    began_at
    ended_at
    login_time
    residence_time
    authori_time
    es_index_date
    es_company_index
    ip_version
    ip_country_code
    ip_country_a_name
    ip_country_j_name
    ip_pref_code
    ip_pref_a_name
    ip_pref_j_name
    ip_city_code
    ip_city_a_name
    ip_city_j_name
    ip_org_name
    ip_domain_name
    ip_line_j_name
    ip_proxy_flag
    ip_pref_cf_level
    ip_city_cf_level
    ip_line_cf_level
    ip_pref_location
    ip_city_location
    ip_read_time
    ip_created
    click_count
    keystroke_count
    mouse_count
    swipe_count
    remarks
    created_at
    updated_at
    labels
    memo
    connected_id
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAuthoriSubscriptionVariables,
  APITypes.OnUpdateAuthoriSubscription
>;
export const onDeleteAuthori = /* GraphQL */ `subscription OnDeleteAuthori($filter: ModelSubscriptionAuthoriFilterInput) {
  onDeleteAuthori(filter: $filter) {
    id
    createdAt
    updatedAt
    expirationUnixTime
    authori_id
    omotion_user_id
    company_id
    send_token
    event_id
    event_name
    user_id_hashed
    user_id_encrypted
    result
    final_result
    reason
    feedback
    feedback_comment
    feedbacked_at
    feedback_type
    feedback_account_id
    feedback_account_name
    user_device_id
    event_url
    login_success
    cookie
    etag
    local_storage
    input_type
    bot_flag
    bot_type
    did_short
    did_middle
    source_ip
    tor_ip_flag
    foreign_ip_flag
    useragent
    wt_device_category_name
    wt_os_name
    wt_os_version
    wt_browser_name
    wt_browser_version
    browser_language
    referer
    timezone_offset
    access_at
    send_at
    request_at
    began_at
    ended_at
    login_time
    residence_time
    authori_time
    es_index_date
    es_company_index
    ip_version
    ip_country_code
    ip_country_a_name
    ip_country_j_name
    ip_pref_code
    ip_pref_a_name
    ip_pref_j_name
    ip_city_code
    ip_city_a_name
    ip_city_j_name
    ip_org_name
    ip_domain_name
    ip_line_j_name
    ip_proxy_flag
    ip_pref_cf_level
    ip_city_cf_level
    ip_line_cf_level
    ip_pref_location
    ip_city_location
    ip_read_time
    ip_created
    click_count
    keystroke_count
    mouse_count
    swipe_count
    remarks
    created_at
    updated_at
    labels
    memo
    connected_id
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAuthoriSubscriptionVariables,
  APITypes.OnDeleteAuthoriSubscription
>;
export const onCreateAuthoriSummaryMonthly = /* GraphQL */ `subscription OnCreateAuthoriSummaryMonthly(
  $filter: ModelSubscriptionAuthoriSummaryMonthlyFilterInput
  $companyId: String
) {
  onCreateAuthoriSummaryMonthly(filter: $filter, companyId: $companyId) {
    companyId
    yearMonth
    ok
    ng
    review
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAuthoriSummaryMonthlySubscriptionVariables,
  APITypes.OnCreateAuthoriSummaryMonthlySubscription
>;
export const onUpdateAuthoriSummaryMonthly = /* GraphQL */ `subscription OnUpdateAuthoriSummaryMonthly(
  $filter: ModelSubscriptionAuthoriSummaryMonthlyFilterInput
  $companyId: String
) {
  onUpdateAuthoriSummaryMonthly(filter: $filter, companyId: $companyId) {
    companyId
    yearMonth
    ok
    ng
    review
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAuthoriSummaryMonthlySubscriptionVariables,
  APITypes.OnUpdateAuthoriSummaryMonthlySubscription
>;
export const onDeleteAuthoriSummaryMonthly = /* GraphQL */ `subscription OnDeleteAuthoriSummaryMonthly(
  $filter: ModelSubscriptionAuthoriSummaryMonthlyFilterInput
  $companyId: String
) {
  onDeleteAuthoriSummaryMonthly(filter: $filter, companyId: $companyId) {
    companyId
    yearMonth
    ok
    ng
    review
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAuthoriSummaryMonthlySubscriptionVariables,
  APITypes.OnDeleteAuthoriSummaryMonthlySubscription
>;
export const onCreateExportRequest = /* GraphQL */ `subscription OnCreateExportRequest(
  $filter: ModelSubscriptionExportRequestFilterInput
  $companyId: String
) {
  onCreateExportRequest(filter: $filter, companyId: $companyId) {
    id
    companyId
    event
    accountId
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    createdAt
    ttl
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateExportRequestSubscriptionVariables,
  APITypes.OnCreateExportRequestSubscription
>;
export const onUpdateExportRequest = /* GraphQL */ `subscription OnUpdateExportRequest(
  $filter: ModelSubscriptionExportRequestFilterInput
  $companyId: String
) {
  onUpdateExportRequest(filter: $filter, companyId: $companyId) {
    id
    companyId
    event
    accountId
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    createdAt
    ttl
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateExportRequestSubscriptionVariables,
  APITypes.OnUpdateExportRequestSubscription
>;
export const onDeleteExportRequest = /* GraphQL */ `subscription OnDeleteExportRequest(
  $filter: ModelSubscriptionExportRequestFilterInput
  $companyId: String
) {
  onDeleteExportRequest(filter: $filter, companyId: $companyId) {
    id
    companyId
    event
    accountId
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    createdAt
    ttl
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteExportRequestSubscriptionVariables,
  APITypes.OnDeleteExportRequestSubscription
>;
export const onUpdateExportResultByCompanyId = /* GraphQL */ `subscription OnUpdateExportResultByCompanyId(
  $filter: ModelSubscriptionExportResultFilterInput
  $companyId: String
) {
  onUpdateExportResultByCompanyId(filter: $filter, companyId: $companyId) {
    companyId
    exportRequestId
    createdAt
    status
    error {
      errorType
      errorCode
    }
    s3Object {
      bucket
      key
      region
    }
    event
    accountId
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    requestAt
    exportedAt
    expiredAt
    count
    ttl
    companyIdAndEvent
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateExportResultByCompanyIdSubscriptionVariables,
  APITypes.OnUpdateExportResultByCompanyIdSubscription
>;
export const onCreateInquiry = /* GraphQL */ `subscription OnCreateInquiry(
  $filter: ModelSubscriptionInquiryFilterInput
  $allowedBy: String
  $companyId: String
) {
  onCreateInquiry(
    filter: $filter
    allowedBy: $allowedBy
    companyId: $companyId
  ) {
    inquiryId
    companyId
    companyName
    accountId
    accountName
    accountEmail
    title
    status
    category
    accessLevel
    referrerUrl
    allowedBy
    companyIdOrAccountId
    companyIdOrAccountIdAndStatus
    companyIdOrAccountIdAndCategory
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInquirySubscriptionVariables,
  APITypes.OnCreateInquirySubscription
>;
export const onUpdateInquiry = /* GraphQL */ `subscription OnUpdateInquiry(
  $filter: ModelSubscriptionInquiryFilterInput
  $allowedBy: String
  $companyId: String
) {
  onUpdateInquiry(
    filter: $filter
    allowedBy: $allowedBy
    companyId: $companyId
  ) {
    inquiryId
    companyId
    companyName
    accountId
    accountName
    accountEmail
    title
    status
    category
    accessLevel
    referrerUrl
    allowedBy
    companyIdOrAccountId
    companyIdOrAccountIdAndStatus
    companyIdOrAccountIdAndCategory
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInquirySubscriptionVariables,
  APITypes.OnUpdateInquirySubscription
>;
export const onDeleteInquiry = /* GraphQL */ `subscription OnDeleteInquiry(
  $filter: ModelSubscriptionInquiryFilterInput
  $allowedBy: String
  $companyId: String
) {
  onDeleteInquiry(
    filter: $filter
    allowedBy: $allowedBy
    companyId: $companyId
  ) {
    inquiryId
    companyId
    companyName
    accountId
    accountName
    accountEmail
    title
    status
    category
    accessLevel
    referrerUrl
    allowedBy
    companyIdOrAccountId
    companyIdOrAccountIdAndStatus
    companyIdOrAccountIdAndCategory
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInquirySubscriptionVariables,
  APITypes.OnDeleteInquirySubscription
>;
export const onCreateInquiryMessage = /* GraphQL */ `subscription OnCreateInquiryMessage(
  $filter: ModelSubscriptionInquiryMessageFilterInput
  $allowedBy: String
  $companyId: String
) {
  onCreateInquiryMessage(
    filter: $filter
    allowedBy: $allowedBy
    companyId: $companyId
  ) {
    inquiryMessageId
    inquiryId
    companyId
    senderAccountType
    senderName
    body
    fileKeys
    cognitoUserIdentityId
    allowedBy
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInquiryMessageSubscriptionVariables,
  APITypes.OnCreateInquiryMessageSubscription
>;
export const onUpdateInquiryMessage = /* GraphQL */ `subscription OnUpdateInquiryMessage(
  $filter: ModelSubscriptionInquiryMessageFilterInput
  $allowedBy: String
  $companyId: String
) {
  onUpdateInquiryMessage(
    filter: $filter
    allowedBy: $allowedBy
    companyId: $companyId
  ) {
    inquiryMessageId
    inquiryId
    companyId
    senderAccountType
    senderName
    body
    fileKeys
    cognitoUserIdentityId
    allowedBy
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInquiryMessageSubscriptionVariables,
  APITypes.OnUpdateInquiryMessageSubscription
>;
export const onDeleteInquiryMessage = /* GraphQL */ `subscription OnDeleteInquiryMessage(
  $filter: ModelSubscriptionInquiryMessageFilterInput
  $allowedBy: String
  $companyId: String
) {
  onDeleteInquiryMessage(
    filter: $filter
    allowedBy: $allowedBy
    companyId: $companyId
  ) {
    inquiryMessageId
    inquiryId
    companyId
    senderAccountType
    senderName
    body
    fileKeys
    cognitoUserIdentityId
    allowedBy
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInquiryMessageSubscriptionVariables,
  APITypes.OnDeleteInquiryMessageSubscription
>;
export const onCreateSavedSearch = /* GraphQL */ `subscription OnCreateSavedSearch(
  $filter: ModelSubscriptionSavedSearchFilterInput
  $companyId: String
) {
  onCreateSavedSearch(filter: $filter, companyId: $companyId) {
    id
    userId
    pagePathSearchId
    pagePath
    searchId
    queryString
    savedAt
    name
    createdAt
    updatedAt
    companyId
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSavedSearchSubscriptionVariables,
  APITypes.OnCreateSavedSearchSubscription
>;
export const onUpdateSavedSearch = /* GraphQL */ `subscription OnUpdateSavedSearch(
  $filter: ModelSubscriptionSavedSearchFilterInput
  $companyId: String
) {
  onUpdateSavedSearch(filter: $filter, companyId: $companyId) {
    id
    userId
    pagePathSearchId
    pagePath
    searchId
    queryString
    savedAt
    name
    createdAt
    updatedAt
    companyId
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSavedSearchSubscriptionVariables,
  APITypes.OnUpdateSavedSearchSubscription
>;
export const onDeleteSavedSearch = /* GraphQL */ `subscription OnDeleteSavedSearch(
  $filter: ModelSubscriptionSavedSearchFilterInput
  $companyId: String
) {
  onDeleteSavedSearch(filter: $filter, companyId: $companyId) {
    id
    userId
    pagePathSearchId
    pagePath
    searchId
    queryString
    savedAt
    name
    createdAt
    updatedAt
    companyId
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSavedSearchSubscriptionVariables,
  APITypes.OnDeleteSavedSearchSubscription
>;
