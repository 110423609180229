/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://bygcjlt4urc2lam2dwrpli77km.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:7aac1c5a-b546-4eb2-8832-f7850a006212",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_akbAm6Dsh",
    "aws_user_pools_web_client_id": "2u19ckkceb79octrgsvtla5nih",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "omt-console-storageprod-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "geo": {
        "amazon_location_service": {
            "region": "ap-northeast-1",
            "maps": {
                "items": {
                    "omtConsoleStandardDarkMap-prod": {
                        "style": "VectorOpenDataStandardDark"
                    },
                    "omtConsoleStandardLightMap-prod": {
                        "style": "VectorOpenDataStandardLight"
                    }
                },
                "default": "omtConsoleStandardLightMap-prod"
            }
        }
    }
};


export default awsmobile;
