import type { DATE_AUTO_COMPLETION_MENU } from 'src/common/utils/Date'
import type { ForeignAccessSize } from 'src/components/ui-groups/charts/helper'
import type {
  AUTHORI_IP_CITY_LOCATION_RESULT,
  authoriIllegalStatusNavigation
} from 'src/containers/pages/analytics/dashboard/helper'
import type { AvailableMapStyle, LocationMapType } from 'src/containers/pages/analytics/dashboard/helper/map'
import type { AggregateAuthoriStatus, CfLevel } from 'src/containers/pages/analytics/dashboard/types'

/** イベントごとの固有属性キー */
export const CUSTOM_ATTRIBUTES_KEY = 'customAttrs'

/**
 * イベントキー
 */
export type RecordEventKey = keyof RecordEventKeyCustomAttributes

/**
 * イベントキーとカスタム属性
 * カスタム属性は`Record<string, string>`である必要がある
 */
export type RecordEventKeyCustomAttributes = {
  // ログイン画面
  login: { result: 'success' | 'failure' }
  logout: undefined
  loginFirstPasswordRegistration: undefined
  loginForgotPassword: { email: string }
  loginForgotPasswordComplete: { result: 'success' | 'failure' }
  // サポートへのリンク
  supportLink: undefined
  // プロフィール
  passwordChange: { result: 'success' | 'failure' }
  // 通知設定
  notificationReportSchedule: { reception: `${boolean}` }
  notificationIpReportSchedule: { reception: `${boolean}` }
  // 審査: かんたんな検索
  authoriSearchSimple: Record<string, string>
  authoriSearchSimpleCsvDownload: Record<string, string>
  // 審査: 高度な検索
  authoriSearchAdvance: Record<string, string>
  authoriSearchAdvanceCsvDownload: Record<string, string>
  // 審査検索
  authoriSearchMultipleFeedback: { feedback?: 'OK' | 'NG'; feedbackComment?: string; label?: string; count: string }
  authoriSearchSaveSearchCondition: undefined
  authoriSearchRestoreSearchCondition: undefined
  authoriSearchDeleteSearchCondition: undefined
  authoriSearchDetailLink: undefined
  authoriSearchFeedbackList: undefined
  authoriSearchOpenHashGenerateModal: undefined
  // 審査詳細
  authoriDetailFeedback: { feedback: 'OK' | 'NG'; feedbackComment: string }
  authoriDetailLabelEdit: undefined
  authoriDetailMemoEdit: undefined
  authoriDetailFeedbackList: undefined
  // 審査: 審査エクスポート
  authoriExport: undefined
  authoriExportDownload: undefined
  authoriExportChangeFilter: undefined
  // 端末: 端末
  userDeviceSearch: Record<string, string>
  // IP: IP集計
  ipAggregationNotificationLink: undefined
  ipAggregation: Record<string, string>
  // IP: IP集計エクスポート
  ipAggregationExport: undefined
  ipAggregationExportDownload: undefined
  ipAggregationExportChangeFilter: undefined
  // 分析: ダッシュボード
  analyticsDashboardSelectPeriod: {
    value: '12時間' | '24時間' | '72時間' | '1週間' | '2週間' | '30日' | '90日' | 'カスタム'
  }
  analyticsDashboardApply: {
    from: string
    to: string
    eventName?: string
    deviceCategoryNames?: string
  }
  analyticsDashboardExpand: {
    type: 'authori' | 'device' | 'illegalAccess' | 'bot' | 'heatmap' | 'foreignAccess' | 'inputType'
  }
  analyticsDashboardAuthoriToggle: { value: AggregateAuthoriStatus }
  analyticsDashboardIllegalAccessToggle: { value: (typeof authoriIllegalStatusNavigation)[number]['key'] }
  analyticsDashboardForeignAccessPulldown: { value: `${ForeignAccessSize}` }
  analyticsDashboardHeatmapMapStyleToggle: { value: AvailableMapStyle }
  analyticsDashboardHeatmapMapTypeToggle: { value: LocationMapType }
  analyticsDashboardHeatmapIpCityCfLevel: { level: `${CfLevel}` }
  analyticsDashboardHeatmapAuthoriToggle: { value: `${(typeof AUTHORI_IP_CITY_LOCATION_RESULT)[number]}` }
  // 分析: レポート
  // アドオン: 日次データ出力
  dailyDataExport: undefined
  dailyDataExportDownload: undefined
  dailyDataExportChangeFilter: undefined
  // アカウント
  accountCreate: { result: 'success' | 'failure' }
  accountEdit: { result: 'success' | 'failure' }
  accountDelete: { result: 'success' | 'failure' }
  accountInvite: { result: 'success' | 'failure' }
  // 監査ログ
  auditLogExport: undefined
  auditLogExportChangeFilter: undefined
  auditLogExportDownload: undefined
  // WIP お問い合わせ
  // 共通
  // プリセット選択
  presetSelect: { value: (typeof DATE_AUTO_COMPLETION_MENU)[number]['value'] }
  // コピー要素のクリック
  clickCopy: {
    type:
      | 'user_id'
      | 'user_device_id'
      | 'user_id'
      | 'os_name'
      | 'os_version'
      | 'browser_name'
      | 'browser_version'
      | 'source_ip'
      | 'country_name'
      | 'domain_name'
      | 'line_name'
      | 'label'
  }
}

/**
 * イベントキーの論理名
 */
export const RECORD_EVENT_KEY_NAMES: Record<RecordEventKey, string> = {
  // ログイン
  login: 'ログインの実行',
  logout: 'ログアウトの実行',
  loginFirstPasswordRegistration: '初回パスワード登録の実行',
  loginForgotPassword: 'パスワード忘れ入力',
  loginForgotPasswordComplete: 'パスワード忘れ完了',
  // トップページ
  supportLink: 'サポートへのリンク',
  // プロフィール
  passwordChange: 'パスワード変更',
  // 通知設定
  notificationReportSchedule: '通知設定: 定期配信の設定',
  notificationIpReportSchedule: '通知設定: IP集計の設定',
  // 審査検索
  authoriSearchSimple: 'かんたんな審査検索: 審査検索の実行',
  authoriSearchSimpleCsvDownload: 'かんたんな審査検索: CSVダウンロード',
  authoriSearchAdvance: '高度な審査検索: 審査検索の実行',
  authoriSearchAdvanceCsvDownload: '高度な審査検索: CSVダウンロード',
  authoriSearchMultipleFeedback: '審査検索: 一括操作の実行',
  authoriSearchSaveSearchCondition: '審査検索: 検索条件の保存',
  authoriSearchRestoreSearchCondition: '審査検索: 検索条件の復元',
  authoriSearchDeleteSearchCondition: '審査検索: 検索条件の削除',
  authoriSearchFeedbackList: '審査検索： フィードバック一覧へのリンク',
  authoriSearchDetailLink: '審査検索：審査詳細へのリンク',
  authoriSearchOpenHashGenerateModal: '審査検索：ハッシュ値生成モーダル',
  // 審査詳細
  authoriDetailFeedbackList: '審査詳細： フィードバック一覧へのリンク',
  authoriDetailFeedback: '審査詳細: 単一フィードバックの実行',
  authoriDetailLabelEdit: '審査詳細: ラベル編集の実行',
  authoriDetailMemoEdit: '審査詳細: メモ編集の実行',
  // 審査エクスポート
  authoriExport: '審査エクスポート: 審査エクスポートの実行',
  authoriExportDownload: '審査エクスポート: ダウンロードの実行',
  authoriExportChangeFilter: '審査エクスポート: フィルターの変更',
  // 端末検索
  userDeviceSearch: '端末検索: 端末検索の実行',
  // IP: IP集計
  ipAggregationNotificationLink: 'IP集計: IP集計レポート設定へのリンク',
  ipAggregation: 'IP集計: IP集計の実行',
  // IP: IP集計エクスポート
  ipAggregationExport: 'IP集計エクスポート: IP集計エクスポートの実行',
  ipAggregationExportDownload: 'IP集計エクスポート: ダウンロードの実行',
  ipAggregationExportChangeFilter: 'IP集計エクスポート: フィルターの変更',
  // 分析: ダッシュボード
  analyticsDashboardSelectPeriod: 'ダッシュボード: 対象期間の選択',
  analyticsDashboardApply: 'ダッシュボード: 条件の適用',
  analyticsDashboardExpand: 'ダッシュボード: グラフの拡大',
  analyticsDashboardAuthoriToggle: 'ダッシュボード: 審査結果の条件のトグル',
  analyticsDashboardIllegalAccessToggle: 'ダッシュボード: 不正傾向アクセスの条件のトグル',
  analyticsDashboardForeignAccessPulldown: 'ダッシュボード: 海外からのアクセス国別割合の条件のプルダウン',
  analyticsDashboardHeatmapMapStyleToggle: 'ダッシュボード: ヒートマップスタイルのトグル',
  analyticsDashboardHeatmapMapTypeToggle: 'ダッシュボード: ヒートマップ種別のトグル',
  analyticsDashboardHeatmapIpCityCfLevel: 'ダッシュボード: ヒートマップ信頼度の変更',
  analyticsDashboardHeatmapAuthoriToggle: 'ダッシュボード: ヒートマップ審査結果条件のトグル',
  // 分析: レポート
  // アドオン: 日次データ出力
  dailyDataExport: '日次データ出力: 日次データ出力の実行',
  dailyDataExportDownload: '日次データ出力: ダウンロードの実行',
  dailyDataExportChangeFilter: '日次データ出力: フィルターの変更',
  // アカウント
  accountCreate: 'アカウント: 作成',
  accountEdit: 'アカウント: 編集',
  accountDelete: 'アカウント: 削除',
  accountInvite: 'アカウント: 招待メール再送信',
  // 監査ログ
  auditLogExport: '監査ログ: 監査ログエクスポートの実行',
  auditLogExportChangeFilter: '監査ログ: フィルターの変更',
  auditLogExportDownload: '監査ログ: ダウンロードの実行',
  // WIP お問い合わせ
  // 共通
  presetSelect: 'プリセット選択',
  clickCopy: 'クリックでコピー'
} as const

type AmplifyAnalyticsDefaultEvents = '_session.start' | '_session.stop' | 'pageView'

/**
 * 監査ログ非対象のイベントを定義
 * 監査ログ機能から除外したいイベントがあればここの配列に追加してください.
 */
export const NO_AUDIT_LOGS_EVENTS: Readonly<(RecordEventKey | AmplifyAnalyticsDefaultEvents)[]> = [
  // デフォルトイベント
  '_session.start',
  '_session.stop',
  // 固有イベント
  'presetSelect',
  'clickCopy'
] as const
